import { on } from '@ngrx/store';
import * as authActions from '../actions/auth.actions';
import { AuthPayload } from '../models/auth.payload';
import * as authStates from '../models/auth.state';
import { createRehydrateReducer } from '../../../shared/functions/rehydrateReducer';

export const reducer = createRehydrateReducer(
    'AUTH-STORE',
    authStates.initialState,
    on(authActions.loadAuth,
        (state) => { return { ...state, expiring: false } }
    ),
    on(authActions.loadAuthSuccess,
        (state, { auth }) => fromSuccess(state, auth)
    ),
    on(authActions.loadAuthFailure,
        (state) => fromFailure(state)
    ),
    on(authActions.setAuth,
        (state, { auth }) => fromSet(state, auth)
    ),
    on(authActions.setAuthComplete,
        (state, { auth }) => fromSetComplete(state, auth)
    ),
    on(authActions.removeAuth,
        (state) => fromRemove(state)
    ),
    on(authActions.removeAuthComplete,
        (state) => fromRemoveComplete(state)
    ),
    on(authActions.loadUserSingle,
        (state, { user, error }) => {
            if (error !== undefined) {
                return { ...state, is_busy: false, is_error: true, userSingle: undefined, last_error: error };
            }
            return { ...state, is_busy: true, expiring: false, userSingle: user, is_error: false, last_error: undefined };
        }
    ),
    on(authActions.setCurrentService,
        (state, { service }) => {
            return { ...state, expiring: false, currentService: service }
        }
    ),
    on(authActions.expiringStarted,
        (state) => {
            return { ...state, expiring: true }
        }
    ),
    on(
        authActions.expiringFinished,
        (state) => {
            return { ...state, expiring: false }
        }
    ),
    on(
        authActions.refreshAuth,
        (state) => {
            return { ...state, is_busy: true }
        }
    ),
    on(
        authActions.refreshAuthComplete,
        (state, { newToken, setIn, expires }) => {
            return { ...state, is_error: false, last_error: undefined, access_token: newToken, set_in: setIn, expires_in: expires, is_busy: false }
        }
    ),
    on(
        authActions.refreshAuthFailure,
        (state, { error }) => {
            return { ...state, is_error: true, last_error: error, is_busy: false }
        }
    ),
    on(
        authActions.refreshTiming,
        (state, { setIn, expires }) => {
            return { ...state, set_in: setIn, expires_in: expires }
        }
    ),
    on(
        authActions.settingUserName,
        (state, { username }) => {
            return { ...state, user_name: username }
        }
    ),
    on(
        authActions.authenticationSuccedded,
        (state) => {
            return { ...state, is_busy: false }
        }
    ),
    on(
        authActions.updateUserSingleRecurrent,
        (state, { user, current }) => {
            return { ...state, userSingle: user, currentService: current }
        }
    ),
    on(
        authActions.fireLegacyUrlCall,
        (state, { typeNumber, locationUrl }) => {
            return { ...state, legacyState: { typeNumber: typeNumber, locationUrl: locationUrl } }
        }
    ),
    on(
        authActions.setLegacyUrlNone,
        (state) => {
            return { ...state, legacyState: undefined }
        }
    )
)


export const getAuthState = (state: authStates.State) => state;
export const getAccessToken = (state: authStates.State) => {
    return state.access_token;
};
export const getIsBusy = (state: authStates.State) => state.is_busy;
export const getRequiresLogin = (state: authStates.State) => state.requires_log_in;

export const getUserSingle = (state: authStates.State) => state.userSingle;
export const getCurrentService = (state: authStates.State) => state.currentService;

export const getUpdatedUserSingle = (state: authStates.State) => state.userSingle;

function fromSetComplete(state: authStates.State, payload: AuthPayload): authStates.State {
    return {
        ...state,
        authorized: true,
        access_token: payload.token,
        expires_in: payload.expires,
        set_in: payload.set_in,
        requires_log_in: false,
        is_busy: true,
        is_error: false,
        suspendedFlag: payload.suspendedFlag
    }
}

function fromSet(state: authStates.State, payload: AuthPayload): authStates.State {
    return {
        ...state,
        authorized: true,
        access_token: payload.token,
        expires_in: payload.expires,
        set_in: payload.set_in,
        requires_log_in: false,
        is_busy: true,
        is_error: false
    }
}

function fromSuccess(state: authStates.State, payload: AuthPayload): authStates.State {
    return {
        ...state, expiring: false,
        authorized: payload.is_logged,
        access_token: payload.token,
        set_in: payload.set_in,
        expires_in: payload.expires,
        requires_log_in: false,
        is_busy: false,
        is_error: false
    };
}

function fromFailure(state: authStates.State): authStates.State {
    return {
        ...state, expiring: false,
        authorized: false,
        access_token: "",
        set_in: 0,
        expires_in: 0,
        requires_log_in: true,
        is_busy: false,
        is_error: false,
        userSingle: undefined,
        currentService: undefined,
        user_name: ""
    }
}

function fromRemove(state: authStates.State): authStates.State {
    return {
        ...state, expiring: false,
        authorized: false,
        access_token: "",
        set_in: 0,
        expires_in: 0,
        requires_log_in: false,
        is_busy: true,
        is_error: false,
        userSingle: undefined,
        currentService: undefined,
        user_name: ""
    }
}

function fromRemoveComplete(state: authStates.State): authStates.State {
    return {
        ...state, expiring: false,
        authorized: false,
        access_token: "",
        set_in: 0,
        expires_in: 0,
        requires_log_in: true,
        is_busy: false,
        is_error: false,
        userSingle: undefined,
        currentService: undefined,
        user_name: "", legacyState: undefined
    }
}
