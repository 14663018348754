<div class="content">
    <main class="main">
        <div class="horizontal-grid-top" [class.collapsed.container]="sideBarCollapse">
            <div class="first-block" [class.collapsed]="sideBarCollapse">
                <div class="back-button" [class.collapsed]="sideBarCollapse" (click)="toggleSideBar()">
                    <mat-icon class="icon" aria-label="Back button"
                        [class.collapsed]="sideBarCollapse">arrow_back_ios_new
                    </mat-icon>
                </div>
            </div>

            <div class="second-block">
                <div class="table-actions" [class.collapsed]="sideBarCollapse" *ngIf="userSuspendedFlag===0">

                    <button attr.aria-label="{{ 'general.refresh' | transloco }}" mat-icon-button
                        (click)="refreshContent()" *ngIf="!this.loadingService.isLoading"
                        [class.collapsed]="sideBarCollapse" matTooltip="{{ 'general.refresh' | transloco }}">
                        <mat-icon>autorenew
                        </mat-icon>
                    </button>

                    <button mat-icon-button attr.aria-label="{{ 'general.createFolder' | transloco }}"
                        *ngIf="!isCreatingFolder" (click)="startCreateNewFolder()" [class.collapsed]="sideBarCollapse"
                        matTooltip="{{ 'general.createFolder' | transloco }}">
                        <mat-icon>
                            create_new_folder</mat-icon>
                    </button>

                    <button mat-icon-button attr.aria-label="{{ 'general.deleteFolder' | transloco }}"
                        *ngIf="!isDeletingFolder" (click)="startDeleteFolder()" [class.collapsed]="sideBarCollapse"
                        [disabled]="onlySystemFolders()" matTooltip="{{ 'general.deleteFolder' | transloco }}">
                        <mat-icon>
                            folder_delete</mat-icon>
                    </button>

                    <button [disabled]="selection.isEmpty() || onlySystemFolders() || isMoveDisabled()"
                        attr.aria-label="{{ 'general.moveFax' | transloco }}" mat-icon-button *ngIf="!isMovingFax"
                        (click)="moveFax()" [class.collapsed]="sideBarCollapse"
                        matTooltip="{{ 'general.moveFax' | transloco }}">
                        <mat-icon>
                            drive_file_move_outline</mat-icon>
                    </button>
                    <button mat-icon-button [class.collapsed]="sideBarCollapse"
                        attr.aria-label="{{ 'general.addTag' | transloco }}" [disabled]="isAddTagDisabled()"
                        *ngIf="isAddTagVisible" matTooltip="{{ 'general.addTag' | transloco }}"
                        (click)="showCreateTagDialog()">
                        <mat-icon>bookmark
                        </mat-icon>
                    </button>
                    <button mat-icon-button [class.collapsed]="sideBarCollapse"
                        attr.aria-label="{{ 'general.removeTag' | transloco }}" (click)="showDeleteTagDialog()"
                        [disabled]="isRemoveTagDisabled()" *ngIf="isRemoveTagVisible"
                        matTooltip="{{ 'general.removeTag' | transloco }}">
                        <mat-icon>bookmark_border
                        </mat-icon>
                    </button>
                    <!-- <button mat-icon-button [class.collapsed]="sideBarCollapse" (click)="replyMessage()"
                        attr.aria-label="{{ 'general.reply' | transloco }}"
                        [disabled]="selection.isEmpty() || selection.selected.length !== 1 || isSecure"
                        matTooltip="{{ 'general.reply' | transloco }}">
                        <mat-icon>reply</mat-icon>
                    </button> -->

                    <button mat-icon-button [class.collapsed]="sideBarCollapse"
                        attr.aria-label="{{ 'general.forward' | transloco }}"
                        [disabled]="selection.isEmpty() || isSecure || (outboundServiceKey === undefined || outboundServiceKey === '')"
                        matTooltip="{{ 'general.forward' | transloco }}" [matMenuTriggerFor]="forwardMenu"
                        *ngIf="isForwardVisible">
                        <mat-icon>forward
                        </mat-icon>
                    </button>

                    <mat-menu #forwardMenu="matMenu">
                        <button mat-menu-item (click)="forwardMessage()" *ngIf="isForwardVisible">
                            <mat-icon>forward</mat-icon>
                            <span>{{ 'general.forwardByFax' | transloco }}</span>
                        </button>
                        <button mat-menu-item (click)="forwardByMail()" *ngIf="isForwardVisible">
                            <mat-icon>alternate_email</mat-icon>
                            <span>{{ 'general.forwardByMail' | transloco }}</span>
                        </button>
                    </mat-menu>


                    <button mat-icon-button [class.collapsed]="sideBarCollapse" [disabled]="selection.isEmpty()"
                        attr.aria-label="{{ 'general.print' | transloco }}" (click)="printMessages()"
                        matTooltip="{{ 'general.print' | transloco }}" *ngIf="isPrintVisible">
                        <mat-icon>print</mat-icon>
                    </button>
                    <button mat-icon-button [class.collapsed]="sideBarCollapse" [disabled]="selection.isEmpty()"
                        attr.aria-label="{{ 'general.download' | transloco }}" *ngIf="isDownloadVisible"
                        matTooltip="{{ 'general.download' | transloco }}" (click)="download()">
                        <mat-icon>cloud_download
                        </mat-icon>
                    </button>

                    <button mat-icon-button [class.collapsed]="sideBarCollapse" [disabled]="!isMarkEnabled"
                        matTooltip="{{ 'general.markRead' | transloco }}" (click)="markReadUnread(true)"
                        attr.aria-label="{{ 'general.markRead' | transloco }}" *ngIf="isMarkVisible">
                        <mat-icon>done
                        </mat-icon>
                    </button>
                    <button mat-icon-button [class.collapsed]="sideBarCollapse" [disabled]="isMarkUnreadDisabled()"
                        matTooltip="{{ 'general.markUnread' | transloco }}" (click)="markReadUnread(false)"
                        attr.aria-label="{{ 'general.markUnread' | transloco }}" *ngIf="isMarkVisible">
                        <mat-icon>markunread
                        </mat-icon>
                    </button>
                    <button mat-icon-button [class.collapsed]="sideBarCollapse" [disabled]="selection.isEmpty()"
                        matTooltip="{{ 'general.delete' | transloco }}" (click)="deleteFax()"
                        attr.aria-label="{{ 'general.delete' | transloco }}" *ngIf="isDeleteVisible">
                        <mat-icon>delete
                        </mat-icon>
                    </button>
                    <button *ngIf="!hideSignOption && isLegacyEnabled" mat-icon-button
                        [class.collapsed]="sideBarCollapse"
                        [disabled]="selection.isEmpty() || selection.selected.length !== 1 || selection.selected[0].thumbnails.length === 0 || lastFolder.name === 'SENT' || lastFolder.name === 'SIGNED_DOCS' || isSecure"
                        matTooltip="{{ 'general.signature' | transloco }}" (click)="signFax()"
                        attr.aria-label="{{ 'general.signature' | transloco }}">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
                <div class="btn-holder" *ngIf="userSuspendedFlag > 0">
                    <button class="btn btn-shadow w-80 compose-btn" (click)="onAccountDetails()"
                        id="btnRedirectSuspend">
                        {{ 'general.updateBillingInfo' | transloco }}
                    </button>
                </div>
                <div class="paginator-block">

                    <div class="pagination-field">

                        <label for="pageNumber" class="page-number-label">{{('Page' | transloco) + ' '}}</label>
                        <mat-form-field id="pageNumber" class="page-input">
                            <input matInput class="text-center" type="number" min="1" [max]="getTotalPage"
                                placeholder="Page" [(ngModel)]="pageNumber" (keyup.enter)="goToPage($event)"
                                (keydown)="onKeyDownPageNumber($event)" (keyup)="onPageChange($event)"
                                (invalid)="invalidPage" (paste)="onPageValuePasted($event)" [disabled]="switchingPage">
                        </mat-form-field>
                        <label id="pageNumberLabel" for="pageNumber" class="page-number-label">{{' ' + ('of' |
                            transloco) + ' ' +
                            getTotalPageLabel() + ' | '}}</label>
                    </div>


                    <mat-paginator class="paginator" [disabled]="dataSource.data.length === 0 || invalidPage"
                        [pageIndex]="getPageIndex()" [pageSize]="15" [length]="totalCount" (page)="pageChanged($event)"
                        [showFirstLastButtons]="true" [hidePageSize]="true"
                        attr.aria-label="{{ 'general.selectPage' | transloco }}"
                        [ngClass]="{'search': lastFolder.id === 'SEARCH'}" id="matpaginator">
                    </mat-paginator>
                </div>
            </div>

        </div>

        <div class="horizontal-grid" [class.compact]="sideBarCollapse">

            <mat-selection-list hideSingleSelectionIndicator="true" id="selection-list" role="listbox" multiple="false"
                [class.collapsed]="sideBarCollapse" #sidemenu [multiple]="false"
                attr.aria-label="{{ 'general.folder' | transloco }}"
                (selectionChange)="folderChange(sidemenu.selectedOptions.selected)">

                <mat-list-option [selected]="searchText !== ''" id="SEARCH" [hidden]="searchText === ''" role="option"
                    class="side-menu-view-messages ellipsis" [disableRipple]="true" [class.collapsed]="sideBarCollapse"
                    aria-label="{{'general.searchResults' | transloco | titlecase}}" [value]="currentSearchFolder">
                    <mat-icon matListItemIcon>search</mat-icon>
                    <div [class.hideFolderName]="sideBarCollapse"
                        matTooltip="{{'general.searchResults' | transloco | titlecase}}">{{'general.searchResults' |
                        transloco | titlecase}}</div>
                </mat-list-option>

                <mat-list-option id="{{ folder.name }}" role="option" aria-label="{{ folder.name }}"
                    class="side-menu-view-messages ellipsis" [disableRipple]="true" [class.collapsed]="sideBarCollapse"
                    *ngFor="let folder of folders" [value]="folder" [selected]="isFolderSelected(folder.id)">

                    <single-item
                        *ngIf="!isRenameFolder || (isRenameFolder && lastFolder.id != folder.id); else renamed_folder"
                        [originalFolderName]="folder.name | titlecase " [isCollapse]="sideBarCollapse"
                        [messagesCount]="getUnreadCount(folder.id)"
                        [isEditable]="!isSystemFolder(folder) && isFolderSelected(folder.id)"
                        (onEdit)="isRenameFolder = true">
                    </single-item>

                    <ng-template #renamed_folder>
                        <rename-folder-item [originalFolderName]="folder.name| titlecase " [folderId]="folder.id"
                            (onApprove)="startRenameFolder($event)" (onDestroy)="isRenameFolder = false">
                        </rename-folder-item>
                    </ng-template>

                </mat-list-option>

            </mat-selection-list>

            <div class="mat-table-container">
                <mat-table id="view-messages-table" matSort matSortDisableClear
                    (matSortChange)="announceSortChange($event)" [dataSource]="dataSource" multiTemplateDataRows>

                    <mat-header-row *matHeaderRowDef="viewMessagesHeader" ngClass="header-row"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: viewMessagesHeader;"></mat-row>

                    <!-- Checkbox Column For -->
                    <ng-container matColumnDef="select">
                        <mat-header-cell *matHeaderCellDef>
                            <mat-checkbox class="check-size" [aria-label]="checkboxLabel()"
                                (change)="masterToggle($event)" [checked]="isAllSelected()"
                                [disabled]="lastFolder.faxTotalCount === 0">
                            </mat-checkbox>
                            <!-- [checked]="selection.hasValue() && isAllSelected()" -->
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" [ngClass]="row.isRead ? '' : 'unread-message'">
                            <mat-checkbox (click)="$event.stopPropagation()" class="check-size"
                                (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
                                [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </mat-cell>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            <p #fax_preview class="fax_preview" thumbPreview
                                thumbSelected={{element.thumbnails[0]?.imageUrl}} (click)="goToDetail(element.faxId)">
                                <mat-icon class="preview-type" aria-label="{{ 'general.preview' | transloco }}">image
                                </mat-icon>
                            </p>
                        </mat-cell>
                    </ng-container>


                    <!-- Date Column -->
                    <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'general.date' | transloco }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{element.date | date: loadDateLocale(): utcOffset }} </mat-cell>
                    </ng-container>

                    <!-- Page Count Column -->
                    <ng-container matColumnDef="page count">
                        <mat-header-cell *matHeaderCellDef> {{'general.pageCount' | transloco }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{element.pageCount}} </mat-cell>
                    </ng-container>

                    <!-- Subject Column -->
                    <ng-container matColumnDef="subject">
                        <mat-header-cell *matHeaderCellDef> {{'general.subject' | transloco }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            <a href="javascript:void(0)" (click)="goToDetail(element.faxId)"
                                role="button">{{element.subject}}</a>
                        </mat-cell>
                    </ng-container>


                    <!-- folder Column -->
                    <ng-container matColumnDef="folder">
                        <mat-header-cell *matHeaderCellDef>
                            Folder
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{element.folder}}
                        </mat-cell>
                    </ng-container>

                    <!-- From Column -->
                    <ng-container matColumnDef="from">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            From </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{element.from | FormatPhoneNumber }} </mat-cell>
                    </ng-container>

                    <!-- Tags Column -->
                    <ng-container matColumnDef="tags">
                        <mat-header-cell *matHeaderCellDef> {{'contacts.tags' | transloco }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index"
                            [ngClass]="element.isRead ? '' : 'unread-message'">
                            <mat-chip-listbox role="listbox" aria-label="side menu option">
                                <mat-chip-option selectable="false" id="{{tag}}" role="option"
                                    *ngFor="let tag of element.tags | slice:0:2; let i = index" aria-label="{{tag}}"
                                    matTooltip="{{tag}}">
                                    {{tag}}
                                </mat-chip-option>
                                <ng-container *ngIf="element.tags.length > 2">
                                    <a href="javascript:void(0)" (mousedown)="element.showTags = true">
                                        <mat-icon class="show-more-tags">more_vert</mat-icon>
                                    </a>

                                </ng-container>

                                <div class="tags-expanded" *ngIf="element.showTags === true">

                                    <mat-icon class="close-tags-expanded"
                                        (click)="element.showTags = false">close</mat-icon>
                                    <mat-chip-option selectable="false" id="{{tag}}" role="option"
                                        *ngFor="let tag of element.tags; let i = index" aria-label="{{tag}}">
                                        {{tag}}
                                    </mat-chip-option>
                                </div>
                            </mat-chip-listbox>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef> {{'general.status' | transloco }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{element.transmissionStatus | titlecase}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="recipient">
                        <mat-header-cell *matHeaderCellDef> Recipient CSID </mat-header-cell>
                        <mat-cell *matCellDef="let element" [ngClass]="element.isRead ? '' : 'unread-message'">
                            {{ element.destinationFaxNumber | FormatPhoneNumber }}
                        </mat-cell>
                    </ng-container>

                    <!-- Row shown when there is no matching data.-->
                    <tr class="mat-row mat-row-no-data" *matNoDataRow>
                        <td class="no-messages-inbox" colspan="9999">
                            {{'inbox.noMessages' | transloco }} <b>{{currentSearchText$ | async}} </b>
                        </td>
                    </tr>


                </mat-table>
            </div>
        </div>

        <view-messages-errors #viewMessagesErrors />


    </main>

    <myportal-printing-area #faxesPrintArea class="top-mar center frame" [show]="false"></myportal-printing-area>
</div>