import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { Fax, FaxFilterInput, FaxSortInput, ForwardFaxToEmail } from '../models/fax.model';
import { Folder, RestrictedFolder, SystemFolder } from '../models/folder.model';
import { TranslocoService } from '@ngneat/transloco';
import {
  CHECK_NEW_MESSAGES_INTERVAL,
  DEFAULT_FAX_SORT_INPUT,
  FOLDER_DEFAULT_COLUMNS,
  FOLDER_DEFAULT_SEARCH,
  FOLDER_DEFAULT_SELECTED,
  FOLDER_SEARCH_COLUMNS,
  FOLDER_SENT_COLUMNS,
  LIMIT_TOTAL_COUNT_DEFAULT,
  SORTABLE_COLUMNS,
  TABLE_DATA,
  ViewMessagesTableData
} from '../models/table.model';
import { ViewMessagesFacadeService } from '../services/view-messages-facade.service';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { SortDirection } from '../models/shared.model';
import { AuthFacadeService } from 'src/app/auth/services/auth-facade.service';
import { MatListOption } from '@angular/material/list';
import { MatDialog } from '@angular/material/dialog';
import { DialogFolderCreate } from './create-new-folder-dialog.component';
import { DialogFolderDelete } from './delete-folder-dialog.component';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SearchbarFacade } from 'src/app/shared/searchbar/searchbar.facade';
import { TagHandleDialogComponent } from './tag-handle-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { SignSingleFaxComponent } from './sign-single-fax.component';
import { TIMEZONES, VIEW_MESSAGES_SYSTEM_FOLDER_ORDER } from 'src/app/shared/constants/constants';
import { DeleteFaxDialogComponent } from './delete-fax-dialog.component';
import { ForwardFaxEmailDialogComponent } from './forward-fax-email-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from 'src/app/shared/services/brand.service';
import { PrintingAreaComponent } from "./printing-area/printing-area.component";
import { LoadingService } from "../../shared/services/loading.service";
import { RenameFolderData } from "./rename-folder-item/rename-folder-item.component";
import { isCorrectFolderName } from "../../shared/functions/stringValidators";
import { ViewMessagesErrorsComponent } from "./errors/view-messages-errors.component";

@Component({
  selector: 'view-messages',
  templateUrl: './view-messages.component.html',
  styleUrls: ['./view-messages.component.scss']
})
export class ViewMessagesComponent implements OnInit, OnDestroy {


  imageURL: string;
  serviceKey: string = "";
  didNumber: string;
  folders: Folder[] | undefined;
  faxes: Fax[] | undefined;
  invalidPage: boolean = false;
  switchingPage: boolean = false;
  hovered: boolean;
  newFolderName = '';
  isCreatingFolder = false;
  isDeletingFolder = false;
  isRenameFolder = false;
  isMovingFax = false;
  viewMessagesHeader: string[] = [];
  dataSource = new MatTableDataSource(TABLE_DATA);
  selection = new SelectionModel<ViewMessagesTableData>(true, []);
  lastFolder: Folder;
  imageListPrint: string[] = [];
  currentFolder$: Observable<Folder>;
  currentSearchText$: Subject<string> = new Subject<string>();
  private subscriptions: Subscription[] = [];
  private terminated$: Subject<boolean> = new Subject<boolean>();
  private toRefresh$: Subject<number> = new Subject<number>();
  private currentSort$: Subject<FaxSortInput> = new Subject<FaxSortInput>();
  isLegacyEnabled: boolean = false;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('faxesPrintArea') faxesPrintArea: PrintingAreaComponent;
  @ViewChild('viewMessagesErrors') viewMessagesErrors: ViewMessagesErrorsComponent;
  sideBarCollapse = false;
  allowedExtensions: string;
  showTags = false;
  userSuspendedFlag: number = 0;


  outboundServiceKey: string = "";

  user: any;
  utcOffset: string;
  showForwardOptions: boolean = false;
  isSecure: any;

  totalCount: number = 0;
  searchText: string = '';
  folderBeforeSearch: Folder;
  lastSort: FaxSortInput;
  reTryNumber: number = 0;
  hideSignOption: boolean;
  pageNumber: number = 1;
  currentOffset: number = 0;
  prevSelected: ViewMessagesTableData[] = [];

  constructor(
    readonly viewMessagesFacadeService: ViewMessagesFacadeService,
    private authFacadeService: AuthFacadeService,
    public _MatPaginatorIntl: MatPaginatorIntl,
    public dialog: MatDialog,
    private searchBarFacade: SearchbarFacade,
    private _snackBarRef: MatSnackBar,
    private translocoService: TranslocoService,
    private route: ActivatedRoute, private router: Router,
    private brandService: BrandService,
    public loadingService: LoadingService) {

    this.dataSource.paginator = this.paginator;

    this.currentSort$.next(DEFAULT_FAX_SORT_INPUT);
    this.currentSort$.pipe(takeUntil(this.terminated$)).subscribe(v => {
      this.lastSort = v;
    });

    this.viewMessagesFacadeService.currentPagination$
      .pipe(takeUntil(this.terminated$))
      .subscribe(v => {
        if (v) {
          this.pageNumber = v.pageNumber;
          this.currentOffset = v.currentOffset;
        }
      });

    this.subscribeToSearchEvent();
    this.subscribeToCurrentFolder();
    this.subscribeToOutboundService();
    this.subscribeToCurrentService();
    this.subscribeToFolderList();
    this.subscribeToFaxList();
    this.subscribeToIsCreatingFolder();
    this.subscribeToRenameFolderResponse();
    this.subscribeToCreateFolderResponse();
    this.subscribeToCreateFolderError();
    this.subscribeToRenameFolderError()
    this.subscribeToIsDeletingFolder();
    this.subscribeToDeleteFolderResponse();
    this.subscribeToDeleteFolderError();
    this.subscribeToRequiresUpdate();
    this.subscribeToSuspendedFlag();
    this.subscribeToTimerEvent();

    this.authFacadeService.userSingle$.subscribe((userSingle) => {
      this.user = userSingle?.userSingle.user;
      this.setUTCOffset();
    });
    this.route.queryParamMap.pipe(map(p => p.get('searchParam')), take(1)).subscribe(value => {
      this.searchBarFacade.setNextSearch(value ?? '');
      this.router.navigate([], { queryParams: { 'searchParam': null }, queryParamsHandling: 'merge' });
    });

    this.authFacadeService.isLegacySet$.pipe(takeUntil(this.terminated$))
      .subscribe((value) => {
        this.isLegacyEnabled = value;
      });
  }

  ngOnInit(): void {

    this.brandService.config$
      .pipe(filter((config: any) => config), take(1))
      .subscribe((config: any) => {
        this.hideSignOption = !config.signAllowed;
      });
    const currentId = localStorage.getItem("portalSessionID") || "";
    this.isLegacyEnabled = currentId !== "";
    this.onlySystemFolders();
  }

  setUTCOffset() {
    if (this.user && this.user.timeZone) {
      let timezone = this.user.timeZone.split('@').join('/');
      this.utcOffset = TIMEZONES.filter((zone: any) => zone['@value'] === timezone)[0]['#text'].split(' ')[0].slice(1, -1);
    }
  }

  loadDateLocale() {
    if (this.user.dateFormat === 'DDMMYYYY' && this.user.timeFormat === 'Hour24') {
      return 'd/M/yyyy, HH:mm'
    } else if (this.user.dateFormat === 'DDMMYYYY' && this.user.timeFormat === 'Hour12') {
      return 'd/M/yyyy, hh:mm a'
    } else if (this.user.dateFormat === 'MMDDYYYY' && this.user.timeFormat === 'Hour24') {
      return 'M/d/yyyy, HH:mm'
    } else if (this.user.dateFormat === 'MMDDYYYY' && this.user.timeFormat === 'Hour12') {
      return 'M/d/yyyy, hh:mm a'
    } else if (this.user.dateFormat === 'YYYYMMDD' && this.user.timeFormat === 'Hour24') {
      return 'yyyy/M/d, HH:mm'
    } else if (this.user.dateFormat === 'YYYYMMDD' && this.user.timeFormat === 'Hour12') {
      return 'yyyy/M/d, hh:mm a'
    }

    return 'd/M/yyyy, HH:mm';
  }

  get getTotalPage() {
    if (this.paginator === undefined) {
      return 1;
    }
    return Math.ceil(this.paginator.length / LIMIT_TOTAL_COUNT_DEFAULT);
  }

  getTotalPageLabel() {
    return this.lastFolder.id === 'SEARCH' ? 'Many' : this.getTotalPage.toString()
  }

  plusSignForSearchFolderResults() {
    if (this.lastFolder?.id === 'SEARCH') {
      return '+';
    }
    return '';
  }

  onPageValuePasted($event: any) {
    const text: string = $event.clipboardData.getData('text/plain');
    const isNumber: boolean = /^\d+$/.test(text);
    if (!isNumber) {
      $event.preventDefault();
    }
  }

  goToPage($event: any) {
    if (this.invalidPage) {
      return
    }

    this.invalidPage = false;
    this.switchingPage = true;
    const isInvalidPage = this.pageNumber < 1 || this.pageNumber > (Math.ceil(this.paginator.length / LIMIT_TOTAL_COUNT_DEFAULT));
    if (isInvalidPage) {
      this.invalidPage = true
    } else {
      this.paginator.pageIndex = this.getPageIndex();
      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    }
    this.switchingPage = false;
  }

  getPageIndex() {
    return Math.abs(this.pageNumber - 1);
  }

  toggleSideBar() {
    this.sideBarCollapse = !this.sideBarCollapse;
  }

  private subscribeToCurrentFolder() {
    this.currentFolder$ = this.viewMessagesFacadeService.selectedFolder$.pipe(takeUntil(this.terminated$));
    this.subscriptions.push(
      this.currentFolder$.pipe(filter((value) => value !== undefined && value.id !== undefined)).subscribe(fld => {
        this.lastFolder = fld;
        this.setColumns(this.lastFolder.id);
        this.updateFolderInList(fld);
        this.faxes = this.lastFolder.faxes ?? [];
        this.populateTable(this.faxes);
        if (this.faxes.length === 0 && this.lastFolder.faxTotalCount > 0 && this.lastFolder.id !== 'SEARCH') {
          if (this.reTryNumber < 3) {
            this.refreshContent(false);
            this.reTryNumber++;
          } else {
            this.reTryNumber = 0;
            this.lastFolder = { ...this.lastFolder, faxTotalCount: 0 };
          }
        } else {
          if (this.reTryNumber > 0) {
            this.reTryNumber = 0;
          }
        }
        this.totalCount = fld.faxTotalCount;
      })
    );
  }

  private subscribeToOutboundService() {
    return this.authFacadeService.userSingle$
      .pipe(
        map(result => result?.userSingle?.user?.services),
        map(svcs => svcs?.filter(svc => svc.serviceType === "OUTBOUND_FAXING")),
        map(svcList => svcList !== undefined ? svcList[0] : undefined),
        takeUntil(this.terminated$)
      ).subscribe(svc => {
        if (svc !== undefined) {
          this.outboundServiceKey = svc.serviceKey;
        } else {
          this.outboundServiceKey = "";
        }
      })
  }

  private subscribeToCurrentService() {
    this.subscriptions.push(
      this.toRefresh$.subscribe(() => {
        this.loadFolders();
      })
    );
    this.subscriptions.push(
      this.authFacadeService.currentService$
        .subscribe((service) => {
          if (service !== undefined) {
            this.serviceKey = service.serviceKey;
            this.didNumber = service.phoneNumber;
            this.isSecure = service.isSecure;
            this.refreshContent();
          }
        })
    );
  }

  private subscribeToFaxList() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.faxList$
        .subscribe(faxes => {
          if (faxes !== undefined) {
            this.faxes = JSON.parse(JSON.stringify(faxes));
            if (this.searchText.length === 0) {
              this.faxes = [];
            } else if (this.searchText.length > 0 && this.lastFolder.id !== 'SEARCH') {
              this.folderBeforeSearch = this.lastFolder;
              this.setColumns(this.lastFolder.id);
            } else if (this.searchText.length > 0 && this.lastFolder.id === 'SEARCH' && this.viewMessagesHeader !== FOLDER_SEARCH_COLUMNS) {
              this.setColumns(this.lastFolder.id);
            }
            const localFaxTotalCount = (
              this.faxes?.length === undefined ?
                0 :
                this.faxes.length
            ) < LIMIT_TOTAL_COUNT_DEFAULT ?
              this.currentOffset + (this.faxes?.length ?? 0) :
              this.currentOffset + LIMIT_TOTAL_COUNT_DEFAULT + 1;
            this.lastFolder = { ...FOLDER_DEFAULT_SEARCH, faxTotalCount: localFaxTotalCount === undefined ? LIMIT_TOTAL_COUNT_DEFAULT : localFaxTotalCount };
            this.totalCount = this.lastFolder.faxTotalCount;
            this.populateTable(this.faxes);
            this.loadingService.setLoading(false);
          }
        })
    );
  }
  get currentSearchFolder() {
    return { ...FOLDER_DEFAULT_SEARCH, faxTotalCount: this.totalCount };
  }

  private subscribeToFolderList() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.folderList$.pipe(distinctUntilChanged())
        .subscribe(folders => {
          if (folders !== undefined) {
            const unsortedFolders = JSON.parse(JSON.stringify(folders));
            this.folders = unsortedFolders.sort(this.sortFolderList);
            this.folders?.forEach(folder => {
              this.setUnreadCount(folder.id, folder.faxCountUnread)
            });
          }
          this.checkAfterLoadingFoldersForCurrent();
        })
    );
  }

  private readonly isOrdinaryFolder = (systemFolderName: number) => {
    return systemFolderName === -1;
  }

  private readonly sortFolderList = (a: Folder, b: Folder) => {
    const firstSystemFolderName = VIEW_MESSAGES_SYSTEM_FOLDER_ORDER.indexOf(a.name.toUpperCase());
    const secondSystemFolderName = VIEW_MESSAGES_SYSTEM_FOLDER_ORDER.indexOf(b.name.toUpperCase());
    if (firstSystemFolderName !== -1 || secondSystemFolderName !== -1) {
      if (this.isOrdinaryFolder(firstSystemFolderName)) {
        return 1;
      }
      if (this.isOrdinaryFolder(secondSystemFolderName)) {
        return -1;
      }
      return firstSystemFolderName - secondSystemFolderName;
    }
    return a.name.localeCompare(b.name);
  };

  private checkAfterLoadingFoldersForCurrent() {
    if (this.lastFolder !== undefined && this.lastFolder.id !== undefined) {
      const a = this.folders?.find(value => value.id === this.lastFolder.id);
      if (a !== undefined) {
        if (this.folderHasChanged(a, this.lastFolder)) {
          this.lastFolder = a;
          this.refreshContent(false);
          return;
        }
      }
    }
    this.refreshContent(false);
  }

  private loadFolders() {
    this.viewMessagesFacadeService.dispatchLoadFolderList(this.serviceKey, this.outboundServiceKey);
  }

  private subscribeToIsCreatingFolder() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.isCreatingFolder$
        .subscribe(value => {
          if (value != undefined) {
            this.isCreatingFolder = value
          }
        })
    );
  }

  private subscribeToCreateFolderResponse() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.createFolderSuccess$
        .subscribe((folder: any) => {
          if (folder !== undefined && this.folders !== undefined) {
            this.viewMessagesErrors.showSnackForCreateFolderSuccess();
            this.toRefresh$.next(new Date().valueOf());
          }
        })
    );

  }

  private subscribeToRenameFolderResponse() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.renameFolderSuccess$
        .subscribe((folder: any) => {
          let a = this.folders?.find(value => value.id === this.lastFolder.id)
          if (a !== undefined && folder != undefined) {
            this.isRenameFolder = false
            a.name = folder.name;
            this.loadFolders();
          }
        })
    );
  }

  private subscribeToCreateFolderError() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.createFolderError$
        .subscribe(value => {
          if (value != undefined) {
            this.viewMessagesErrors.showSnackForCreateFolderError();
          }
        })
    );
  }

  private subscribeToRenameFolderError() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.renameFolderError$
        .subscribe(value => {
          if (value != undefined) {
            this.viewMessagesErrors.showSnackForRenameFolderError();
          }
        })
    );
  }

  startCreateNewFolder() {
    this.openCreateNewFolderDialog();
  }

  private openCreateNewFolderDialog() {
    this.newFolderName = '';
    const dialogRef = this.dialog.open(DialogFolderCreate, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '400px',
      data: { name: this.newFolderName, errorMessage: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && isCorrectFolderName(result.toUpperCase())) {
        this.submitCreateNewFolder(result);
      } else if (result && !isCorrectFolderName(result)) {
        this.openCreateNewFolderDialogWithError(result);
      }
    });
  }

  private openCreateNewFolderDialogWithError(name: string) {
    const dialogRef = this.dialog.open(DialogFolderCreate, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '400px',
      data: { name, errorMessage: this.translocoService.translate('error.folder.invalidForlderName') }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && isCorrectFolderName(result)) {
        this.submitCreateNewFolder(result);
      } else if (result && !isCorrectFolderName(result)) {
        this.openCreateNewFolderDialogWithError(result);
      }
    });
  }

  private submitCreateNewFolder(newFolderName: string) {
    this.viewMessagesFacadeService.dispatchCreateNewFolder({
      serviceKey: this.serviceKey,
      input: { name: newFolderName }
    });
  }

  private subscribeToIsDeletingFolder() {
    this.subscriptions.push(this.viewMessagesFacadeService.isDeletingFolder$
      .subscribe(value => {
        if (value != undefined) {
          this.isDeletingFolder = value
        }
      }));
  }

  private subscribeToDeleteFolderResponse() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.deleteFolderSuccess$
        .subscribe((value: any) => {
          if (value !== undefined) {
            this.folders =
              this.folders?.filter(folder => folder.id !== value.id);
            this.viewMessagesErrors.showSnackForDeleteFolderSuccess();
          }
        })
    );
  }

  private subscribeToDeleteFolderError() {
    this.subscriptions.push(
      this.viewMessagesFacadeService.deleteFolderError$
        .subscribe(value => {
          if (value != undefined) {
            this.viewMessagesErrors.showSnackForDeleteFolderError();
          }
        })
    );
  }

  startDeleteFolder() {
    this.openFolderDialog();
  }

  moveFax() {

    if (this.isMoveDisabled()) {
      return;
    }

    let folders = this.folders?.filter(item => item.name !== 'SIGNED_DOCS'
      && item.name !== 'DOCUMENTS'
      && item.name !== 'SENT'
      && item.name !== 'TRASH'
      && item.name !== this.lastFolder.name);


    const dialog = this.dialog.open(DialogFolderDelete, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      minWidth: '400px',
      disableClose: true,
      data: { folders: folders, type: 'move' },
    });

    dialog.afterClosed().subscribe(result => {
      if (result?.id) {
        const faxIds = this.selection.selected.map(v => v.faxId);
        const initialTotalCount = this.totalCount;
        const firstSnackBarRef = this._snackBarRef.open(this.translocoService.translateObject('messages.movefaxes'), '', {
          duration: 5000
        });
        this.submitMoveMaxes(result.id, faxIds);
        this.selection.clear();
        this.updateUnreadCountAfterMove(faxIds, result.id);
        firstSnackBarRef.afterDismissed().subscribe(() => {
          this._snackBarRef.open(this.translocoService.translateObject('messages.completed.movedFaxes'), '', {
            duration: 5000
          });
          this.totalCount = initialTotalCount - faxIds.length;
        });
      }
    });
  }

  private updateUnreadCountAfterMove(faxIds: string[], targetFolderId: string) {
    const folderUnreadCountMap: { [folderId: string]: number } = {};
    folderUnreadCountMap[targetFolderId] = this.getUnreadCount(targetFolderId);

    this.faxes?.forEach(fax => {
      if (faxIds.includes(fax.faxId)) {
        const sourceFolderId = fax.folderId !== '' ? fax.folderId : fax.folderName;
        if (!folderUnreadCountMap[sourceFolderId]) {
          folderUnreadCountMap[sourceFolderId] = this.getUnreadCount(sourceFolderId);
        }
        if (!fax.isRead) {
          folderUnreadCountMap[sourceFolderId] -= 1;
          folderUnreadCountMap[targetFolderId] += 1;
        }
      }
    });

    for (const folderId in folderUnreadCountMap) {
      this.setUnreadCount(folderId, folderUnreadCountMap[folderId]);
    }
  }

  private submitMoveMaxes(folderId: string, faxes: string[]) {

    const filter: FaxFilterInput = { folder: this.lastFolder.name };

    this.viewMessagesFacadeService.dispatchMoveFaxes({
      serviceKey: this.getCurrentServiceKey(),
      folderId: folderId,
      faxIds: faxes,
      filter: filter
    });
  }

  private openFolderDialog() {

    let folders = this.folders?.filter(item => item.name !== 'SIGNED_DOCS'
      && item.name !== 'DOCUMENTS'
      && item.name !== 'SENT'
      && item.name !== 'TRASH' && item.name !== 'INBOX');

    const dialog = this.dialog.open(DialogFolderDelete, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '400px',
      data: { folders: folders, type: 'delete' },
    });

    dialog.afterClosed().subscribe(result => {
      this.submitDeleteFolder(result.id)
    });
  }

  private submitDeleteFolder(folderId: string) {
    if (folderId === this.lastFolder.id) {
      this.lastFolder = FOLDER_DEFAULT_SELECTED;
    }
    this.viewMessagesFacadeService.dispatchDeleteFolder({
      serviceKey: this.serviceKey,
      input: { id: folderId }
    });
  }

  isPageNumberOutOfPage() {
    return this.pageNumber < 1 || this.pageNumber > this.getTotalPage;
  }

  isInPageRange(page: number) {
    const minPageNumber = Math.min(1, this.getTotalPage);
    const maxPageNumber = this.getTotalPage;
    return page >= minPageNumber && page <= maxPageNumber;
  }

  pageChanged($event: PageEvent) {
    if (this.isPageNumberOutOfPage()) {
      return
    }
    this.currentOffset = ($event.pageIndex * $event.pageSize);
    this.pageNumber = $event.pageIndex + 1;
    this.viewMessagesFacadeService.setPagination($event.pageIndex, this.pageNumber, this.currentOffset);
    this.reloadFolder();
  }

  onKeyDownPageNumber($event: any) {
    const key = $event.key;
    if (key === 'e') {
      $event.preventDefault();
      return;
    }
    const value: number | null = parseInt(`${this.pageNumber}${key}`);
    if (!(isNaN(value) || this.isInPageRange(value))) {
      $event.preventDefault();
    }
  }


  onPageChange($event: any) {
    const field = document.getElementById("pageNumber");
    if (!field) {
      return
    }

    const invalid: boolean = this.isPageNumberOutOfPage();
    if (invalid) {
      $event.preventDefault();
      this.invalidPage = true
      field.className = field.className + " mdc-text-field--invalid";
      return
    }

    this.invalidPage = false
    field.className = field.className.replace(' mdc-text-field--invalid', '');
  }

  private populateTable(faxes: Fax[] | undefined) {

    if (faxes === undefined) {
      return
    }

    const array: ViewMessagesTableData[] = [];

    for (const fax of faxes) {
      if (!fax.outboundData) {
        continue;
      }
      array.push({
        from: fax.originatingFaxMachine || fax.originatingFaxNumber,
        folder: fax.folderName,
        subject: fax.outboundData.subject,
        tags: fax.tags,
        date: fax.completedTimestamp,
        isRead: fax.isRead,
        pageCount: fax.pages,
        faxId: fax.faxId,
        imageUrl: fax.imageUrl,
        thumbnails: fax.thumbnails,
        originatingFaxMachine: fax.originatingFaxMachine,
        destinationFaxNumber: fax.destinationFaxNumber,
        transmissionStatus: fax.transmissionStatus.toString()
      })
    }

    this.dataSource = new MatTableDataSource(array);

    this.handleFaxesSelection();
  }

  private handleFaxesSelection() {
    this.selection.clear();

    if (!this.prevSelected) {
      this.selection.clear();
      return;
    }

    const faxIds = this.prevSelected.map(f => f.faxId);
    if (faxIds !== undefined && faxIds.length > 0) {
      this.selection.select(...this.dataSource.data.filter(value => faxIds.includes(value.faxId)));
    }

  }

  refreshContent(includeFolders: boolean = true) {
    if (includeFolders) {
      this.toRefresh$.next(new Date().valueOf());
    } else {
      this.reloadFolder();
    }
  }

  isAllSelected() {
    return this.selection.hasValue() && this.selection.selected.length === this.dataSource.data.length;
  }

  masterToggle(event: MatCheckboxChange) {
    if (!event.checked) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  checkboxLabel(row?: ViewMessagesTableData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.from + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  toggleDirection(direction: string) {
    if (direction === 'asc') {
      return SortDirection.ASC
    } else {
      return SortDirection.DESC
    }
  }

  announceSortChange(sortState: any) {
    if (SORTABLE_COLUMNS.includes(sortState.active)) {
      this.currentSort$.next({ sortDirection: this.toggleDirection(sortState.direction), sortBy: sortState.active != 'recipient' ? sortState.active.toUpperCase() : "TO" });
      this.paginator.firstPage();
      this.refreshContent(false);
    }
  }

  folderChange(options: MatListOption[]) {
    this.isRenameFolder = false
    this.prevSelected = [];
    const selectedFolder = options.map(o => o.value)[0] as Folder;
    this.changingFolder(selectedFolder);
  }

  private changingFolder(selectedFolder: Folder) {
    this.lastFolder = selectedFolder;
    this.totalCount = this.lastFolder.faxTotalCount;
    this.paginator.length = this.totalCount;
    this.paginator.firstPage();
    this.viewMessagesFacadeService.setPagination(0, 1, 0);
    this.pageNumber = 1;
    this.currentOffset = 0;
    this.reloadFolder();
    // this.refreshContent();
  }

  ngOnDestroy(): void {
    this.terminated$.next(true);
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setColumns(id: string) {
    if (id === 'SENT') {
      this.viewMessagesHeader = FOLDER_SENT_COLUMNS;
    } else if (id === 'SEARCH') {
      this.viewMessagesHeader = FOLDER_SEARCH_COLUMNS;
    }
    else {
      this.viewMessagesHeader = FOLDER_DEFAULT_COLUMNS;
    }
  }

  reloadFaxes() {
    const options = {
      inboundServiceKey: this.serviceKey,
      outboundServiceKey: this.outboundServiceKey,
      filter: { searchText: this.searchText, searchType: 'METADATA' },
      offset: this.currentOffset,
      limit: LIMIT_TOTAL_COUNT_DEFAULT,
      sort: this.lastFolder.id !== SystemFolder.SENT ? this.lastSort : DEFAULT_FAX_SORT_INPUT
    }
    if (this.paginator !== undefined && this.paginator.showFirstLastButtons) this.paginator.showFirstLastButtons = false;
    this.viewMessagesFacadeService.dispatchLoadFaxList(options);
  }

  reloadFolder() {
    if (this.lastFolder.id !== 'SEARCH') {
      const limit = this.lastFolder.faxTotalCount === 0 ? -1 : (LIMIT_TOTAL_COUNT_DEFAULT > this.lastFolder.faxTotalCount) ?
        this.lastFolder.faxTotalCount : LIMIT_TOTAL_COUNT_DEFAULT;
      const currentServiceKey: string = this.lastFolder.id !== SystemFolder.SENT ? this.serviceKey : this.outboundServiceKey;
      const sort = this.lastFolder.id !== SystemFolder.SENT ? this.lastSort : DEFAULT_FAX_SORT_INPUT;
      if (this.paginator !== undefined && !this.paginator.showFirstLastButtons) this.paginator.showFirstLastButtons = true;
      this.viewMessagesFacadeService.dispatchReloadFolder(currentServiceKey, this.lastFolder.name, this.currentOffset, limit, sort);
    } else if (this.searchText.length > 0) {
      this.reloadFaxes();
    }
  }

  forwardMessage() {
    const faxes = this.getSelectedFaxes().map(v => { return { serviceKey: v.serviceKey, faxId: v.faxId, pages: v.pages } });
    this.viewMessagesFacadeService.getSelectedFaxes(faxes)
      .pipe(take(1)).subscribe((results: Fax[]) => {
        if (results !== undefined && results.length > 0) {
          if (results.length === 1) {
            this.viewMessagesFacadeService.forwardMessage(results[0]);
          } else {
            this.viewMessagesFacadeService.forwardMultipleMessage(results)
          }
        }
      }, (err) => {
        console.error("Error when forwarding from message centre", err);
      }, () => {
        this.selection.clear();
      });
  }

  forwardByMail() {
    const faxIds = this.selection.selected.map(v => v.faxId);
    const dialog = this.dialog.open(ForwardFaxEmailDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '500px'
    });

    dialog.afterClosed().pipe(filter(r => r !== 'cancel' && r.mailsList?.length > 0),
      take(1),
      map(d => { return { mailsList: d.mailsList, subject: d.subject, message: d.message } }),
      switchMap(result => this.viewMessagesFacadeService.forwardFaxToEmail(this.getCurrentServiceKey(), result.subject, result.message, faxIds, result.mailsList)),
      map((r: any) => r.forwardFaxToEmail as ForwardFaxToEmail)
    )
      .subscribe(result => {
        if (result.emailSuccess?.length === 0) {
          this._snackBarRef.open(this.translocoService.translateObject('messages.noForwardToMail'), undefined, {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['error-snack-bar']
          });
        } else {
          this._snackBarRef.open(`${this.translocoService.translateObject('messages.successForwardToMail')} ${result.emailSuccess?.join(', ')}`, undefined, {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['success-snack-bar']
          });
        }
        this.selection.clear();
      }, () => {
        this._snackBarRef.open(this.translocoService.translateObject('messages.noForwardToMail'), undefined, {
          duration: 5000,
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: ['error-snack-bar']
        });
      });
  }

  replyMessage() {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      minWidth: '400px'
    });
    dialog.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === 1) {
        const message = this.getSelectedFaxes()[0];
        this.viewMessagesFacadeService.replyMessage(message, true);
      } else if (result === 2) {
        const message = this.getSelectedFaxes()[0];
        this.viewMessagesFacadeService.replyMessage(message, false);
      } else {
      }
    });
  }


  printMessages() {
    const faxes = this.selection.selected.map(item => item.faxId);
    this.viewMessagesFacadeService.getSelectedFaxesImages(this.getCurrentServiceKey(), faxes)
      .pipe(take(1)).subscribe(results => {
        if (results !== undefined && results.length > 0) {
          const _this = this;
          this.faxesPrintArea.setValue(results.flat()).then(() => {
            _this.faxesPrintArea.printing();
          });
        }
      }, (err) => {
        console.error("Error when printing", err);
      }, () => {
        this.selection.clear();
      });
  }

  private getSelectedFaxes() {
    const ids = this.selection.selected.map(item => item.faxId);
    return this.faxes?.filter(item => ids.includes(item.faxId)) as Fax[];
  }

  markReadUnread(isRead: boolean) {
    const faxIdList = this.selection
      .selected
      .filter(el => !el.isRead)
      .map(el => el.faxId)

    this.viewMessagesFacadeService.markAsReadOrUnread(this.getCurrentServiceKey(), faxIdList, isRead);

    const currentUnreadCount = this.getUnreadCount(this.lastFolder.id);
    this.setUnreadCount(this.lastFolder.id, isRead ? currentUnreadCount - faxIdList.length : currentUnreadCount + faxIdList.length);
  }

  getUnreadCount(folderId: string): number {
    const storedCount = sessionStorage.getItem(`unreadCount_${folderId}`);
    return storedCount !== null ? parseInt(storedCount, 10) : 0;
  }

  setUnreadCount(folderId: string, count: number): void {
    sessionStorage.setItem(`unreadCount_${folderId}`, count.toString());
  }

  deleteFax() {
    const msg: string = Object.keys(RestrictedFolder).includes(this.lastFolder.name) ? 'view-messages.delete.fax.confirm.warning' : 'view-messages.delete.fax.confirm'
    const faxIds = this.selection.selected.map(v => v.faxId);
    const dialog = this.dialog.open(DeleteFaxDialogComponent, {
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
      disableClose: true,
      data: {
        msg: msg,
        count: faxIds.length
      },
    });

    dialog.afterClosed().subscribe(result => {
      if (result === 'confirm') {
        this.confirmDeleteFaxes();
        this.selection.clear();
      }
    });
  }

  private confirmDeleteFaxes() {
    const filter: FaxFilterInput = { folder: this.lastFolder.name };
    const faxIds = this.selection.selected.map(v => v.faxId);
    const flush = Object.keys(RestrictedFolder).includes(this.lastFolder.name);
    const func = of(this.viewMessagesFacadeService.deleteFaxList(this.getCurrentServiceKey(), faxIds, filter, this.lastFolder.id, flush));
    func.pipe(take(1)).subscribe(() => {
      this.refreshContent();
    });
  }

  private downloadBlob(url: string, name: string) {

    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.target = "_blank";

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url);
      link.remove();
    }, 100);
  }

  download() {
    if (this.selection.selected.length === 1) {
      const currentFax = this.faxes?.find(f => f.faxId === this.selection.selected[0].faxId);
      if (!!currentFax?.imageUrl) {

        const startsFrom = currentFax.imageUrl.lastIndexOf("/") + 1;
        const endsAt = currentFax.imageUrl.indexOf("?", startsFrom) - 1;
        const fileName = currentFax.imageUrl.substring(startsFrom, endsAt);

        this.downloadBlob(currentFax.imageUrl, fileName)

        //this.viewMessagesFacadeService.dispatchDownloadSingleFax(fileName, currentFax.imageUrl);
      } else {
        console.error(`Unable to download due to a lack of data: url: ${currentFax?.imageUrl}`);
      }
      this.selection.clear();
    } else {

      let faxIds: string[] = [];

      this._snackBarRef.openFromComponent(SnackBarGenerateZip, {
        duration: 10000
      });

      this.selection.selected.forEach((selected) => {
        faxIds.push(selected.faxId);
      })

      this.viewMessagesFacadeService.downloadbulk(this.getCurrentServiceKey(), faxIds);
    }
    this.selection.clear();
  }

  goToDetail(faxId: string) {
    const currentFax = this.faxes?.find(f => f.faxId === faxId);
    if (currentFax !== undefined) {
      this.viewMessagesFacadeService.dispatchShowSingleFax(currentFax)
    } else {
      console.error(`Unable to find the fax, faxId: ${faxId}`);
    }
  }

  showCreateTagDialog() {
    const currentFaxes = this.selection.selected;
    if (currentFaxes !== undefined) {
      const dialog = this.dialog.open(TagHandleDialogComponent, {
        backdropClass: 'cdk-overlay-transparent-backdrop',
        hasBackdrop: true,
        disableClose: true,
        minWidth: '400px',
        data: {
          isCreating: true,
          tags: currentFaxes.length === 1 ? currentFaxes[0].tags : [],
          selectedTag: ""
        }
      });

      dialog.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) {
          if (currentFaxes.length === 1) {
            const fax = this.faxes?.find(f => f.faxId === currentFaxes[0].faxId)
            if (fax !== undefined) {
              this.viewMessagesFacadeService.dispatchTagAdd(fax.serviceKey, fax.faxId, result);
            }
          } else {
            this.submitAddTagList(result, currentFaxes);
          }
          this.selection.clear();
        }
      });
    }
  }

  private submitAddTagList(tag: string, selectedFaxes: ViewMessagesTableData[]) {
    this.selection.clear();
    selectedFaxes.forEach(element => {
      const fax = this.faxes?.find(f => f.faxId === element.faxId)
      if (fax !== undefined) {
        this.viewMessagesFacadeService.dispatchTagAdd(fax.serviceKey, fax.faxId, tag);
      }
    });
  }

  showDeleteTagDialog() {
    const currentFax = this.faxes?.find(f => f.faxId === this.selection.selected[0].faxId);
    if (currentFax !== undefined) {
      const dialog = this.dialog.open(TagHandleDialogComponent, {
        backdropClass: 'cdk-overlay-transparent-backdrop',
        hasBackdrop: true,
        disableClose: true,
        minWidth: '400px',
        data: { isCreating: false, tags: currentFax.tags, selectedTag: "" },
      });

      dialog.afterClosed().pipe(take(1)).subscribe(result => {
        if (result) {
          this.viewMessagesFacadeService.dispatchTagDelete(currentFax.serviceKey, currentFax.faxId, result);
          this.selection.clear();
        }
      });
    }
  }

  signFax() {
    const currentFax = this.selection.selected[0].faxId;
    if (currentFax !== undefined) {
      const dialog = this.dialog.open(SignSingleFaxComponent, {
        width: '80%',
        hasBackdrop: true,
        disableClose: true,
        data: { faxId: currentFax, did: this.didNumber }
      });
      dialog.afterClosed().pipe(take(1)).subscribe(() => {
        this.selection.clear();
      });
    }
  }

  private getCurrentServiceKey(): string {
    return this.lastFolder.id === 'SENT' ? this.outboundServiceKey ?? this.serviceKey : this.serviceKey ?? this.outboundServiceKey;
  }

  private folderHasChanged(candidate: Folder | undefined, current: Folder): boolean {
    return (candidate === undefined) ? false :
      (candidate?.faxTotalCount !== current?.faxTotalCount || candidate?.faxCountRead !== current?.faxCountRead || candidate?.faxCountUnread !== current?.faxCountUnread)
  }

  private updateFolderInList(fld: Folder) {
    if (this.folders !== undefined) {
      const index = this.folders.findIndex(s => s.id === fld.id);
      if (~index) {
        if (this.folderHasChanged(fld, this.folders[index])) {
          this.folders[index] = { ...fld };
        }
      }

    }
  }

  private subscribeToRequiresUpdate() {
    this.viewMessagesFacadeService.requiresUpdateFolderCache$
      .pipe(takeUntil(this.terminated$))
      .subscribe(value => {
        if (value) {
          this.refreshContent(false);
        }
      })
  }

  private subscribeToSuspendedFlag() {
    this.authFacadeService.authState$.pipe(
      takeUntil(this.terminated$),
      map(s => s.suspendedFlag)
    ).subscribe(value => {
      this.userSuspendedFlag = value;
    })
  }

  private subscribeToSearchEvent() {
    this.subscriptions.push(
      this.searchBarFacade.searchChanged.subscribe(value => {
        this.currentSearchText$.next(value);
        this.searchText = value.trim();
        if (this.searchText.length > 0 && this.lastFolder.id !== 'SEARCH') {
          this.loadingService.setLoading(true);
          this.folderBeforeSearch = this.lastFolder;
          this.lastFolder = { ...FOLDER_DEFAULT_SEARCH, faxTotalCount: this.currentOffset + LIMIT_TOTAL_COUNT_DEFAULT + 1 };
          this.totalCount = this.lastFolder.faxTotalCount;
          this.setColumns(this.lastFolder.id);
          this.refreshContent(false);
        } else if (this.searchText.length === 0 && this.lastFolder.id === 'SEARCH' && this.folderBeforeSearch !== undefined) {
          this.lastFolder = this.folderBeforeSearch;
          this.setColumns(this.lastFolder.id);
          this.refreshContent(false);
        } else if (this.searchText.length > 0 && this.lastFolder.id === 'SEARCH') {
          this.loadingService.setLoading(true);
          this.refreshContent(false);
        }
      })
    );
  }

  private subscribeToTimerEvent() {
    this.subscriptions.push(
      timer(CHECK_NEW_MESSAGES_INTERVAL, CHECK_NEW_MESSAGES_INTERVAL).pipe(takeUntil(this.terminated$))
        .subscribe(value => {

          this.prevSelected = [...this.selection.selected];

          if (this.lastFolder.id !== 'SEARCH') {
            this.toRefresh$.next(value);
          } else {
            this.refreshContent(false);
          }
        })
    );
  }

  isAddTagDisabled() {
    return this.selection.isEmpty() || this.lastFolder.name === 'DOCUMENTS' || this.isSecure;
  }

  isRemoveTagDisabled() {
    return this.selection.isEmpty() ||
      this.selection.selected.length !== 1 ||
      this.lastFolder.name === 'DOCUMENTS' ||
      this.selection.selected[0].tags.length === 0 ||
      this.isSecure;
  }

  isMarkUnreadDisabled() {
    return this.selection.isEmpty() ||
      this.selection.selected.length !== 1 ||
      !this.selection.selected[0].isRead;
  }

  isMarkReadDisabled() {
    return this.selection.isEmpty() ||
      this.selection.selected.length !== 1 ||
      this.selection.selected[0].isRead;
  }

  get isMarkEnabled() {
    return !this.selection.isEmpty() &&
      this.selection.selected.filter(el => !el.isRead).length > 0
  }

  get isAddTagVisible() {
    return true;
  }
  get isRemoveTagVisible() {
    return true;
  }
  get isForwardVisible() {
    return true;
  }
  get isPrintVisible() {
    return true;
  }
  get isDownloadVisible() {
    return true;
  }
  get isMarkVisible() {
    return true;
  }
  get isDeleteVisible() {
    return true;
  }

  onlySystemFolders() {
    const systemFolders = new Set(Object.values(SystemFolder));
    const hasAnotherFolder = this.folders ? this.folders.some(folder => !systemFolders.has(folder.name as SystemFolder)) : false;
    return !hasAnotherFolder;
  }

  isSystemFolder(folder: Folder): boolean {
    return Object.values(SystemFolder).includes(folder.id as SystemFolder) || Object.values(SystemFolder).includes(folder.name as SystemFolder);
  }

  startRenameFolder(renameFolderData: RenameFolderData) {
    if (this.lastFolder.name == renameFolderData.folderName) {
      this.isRenameFolder = false
    } else {
      this.viewMessagesFacadeService.dispatchRenameFolder({
        serviceKey: this.serviceKey,
        input: {
          id: renameFolderData.folderId,
          name: renameFolderData.folderName
        }
      })
    }
  }

  isFolderSelected(folderId: string) {
    return folderId === this.lastFolder.id && this.searchText === ''
  }

  onAccountDetails() {
    this.router.navigate(['/account-details']);
  }

  isMoveDisabled(): boolean {
    const restrictedFolders = ['SENT', 'SIGNED_DOCS', 'DOCUMENTS'];
    return restrictedFolders.includes(this.lastFolder.name.toUpperCase());
  }
}


@Component({
  selector: 'snack-bar-generate-zip',
  templateUrl: 'snack-bar-generate-zip.html'
})
export class SnackBarGenerateZip { }
